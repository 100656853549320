// AiLogContainer.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {useCookies} from 'react-cookie'
import { MarkdownMessage } from './Utils';

const api_url = process.env.REACT_APP_API_URL;

const agent_alias = "HAI";
const user_alias = "User";

// Define the custom event name
const TRIGGER_STREAM_EVENT = 'triggerAiStream';

const AiLogContainer = ({ token, sessionId }) => {
    const [aiLogs, setAiLogs] = useState([
        { role: agent_alias, content: "I gathered some recommendations for you. Check them out and approve if you agree :)", id: -1 }
    ]);
    const [userInput, setUserInput] = useState("");
    const aiLogRef = useRef(null);
    const textareaRef = useRef(null);
    const [customerId, setCustomerId, removeCustomerID] = useCookies(['customer_id'])
    const [isExpanded, setIsExpanded] = useState(false);
    const isExpandedRef = useRef(isExpanded);
    const [isThinking, setIsThinking] = useState(false);
    const [newMessageCount, setNewMessageCount] = useState(0);
    const eventSourceRef = useRef(null); // Ref to hold the EventSource instance

    // --- Helper function to scroll chat ---
    const scrollToBottom = () => {
        setTimeout(() => {
            if (aiLogRef.current) {
                requestAnimationFrame(() => {
                    aiLogRef.current.scrollTo({
                        top: aiLogRef.current.scrollHeight,
                        behavior: "smooth"
                    });
                });
            }
        }, 100); // Short delay to allow DOM updates
    };

    // Reset new message count when chat box is expanded
    useEffect(() => {
        isExpandedRef.current = isExpanded;
        if (isExpanded) {
            setNewMessageCount(0);
            scrollToBottom(); // Scroll when expanding
        }
    }, [isExpanded]);

    // Fetch previous chat messages
    const fetchChatMessages = useCallback(async () => {
        if (!sessionId || !token) {
            console.error("Missing session_id. Cannot fetch chat messages.");
            return;
        }

        try {
            const response = await fetch(`${api_url}/api/chat-messages/`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
                },
                body: JSON.stringify({ mytoken: token, session_id: sessionId })
            });

            const data = await response.json();
            if (response.ok && Array.isArray(data.messages)) {
                setAiLogs(prevLogs => {
                    const existingIds = new Set(prevLogs.map(msg => msg.id));

                    const newMessages = data.messages
                        .filter(msg => !existingIds.has(msg.id))
                        .map(msg => ({
                            "role": msg.role.replace(/^assistant$/, agent_alias).replace(/^user$/, user_alias),
                            "content": msg.content,
                            "id": msg.id
                                
                        }));

                        if (newMessages.length > 0) {
                            // ✅ Scroll to bottom when new messages appear
                            scrollToBottom();
                        }

                    return [...prevLogs, ...newMessages];
                });
            } else {
                console.error("Error fetching messages:", data.error);
            }
        } catch (error) {
            console.error("Error fetching chat messages:", error);
        }
    }, [token, sessionId]); // Add dependencies;

    // --- Function to CLOSE the SSE connection ---
    const closeSSE = useCallback(() => {
        if (eventSourceRef.current) {
            console.log("🔴 Closing SSE stream from frontend...");
            eventSourceRef.current.close();
            eventSourceRef.current = null;
        }
        // Allow sending new messages only after streaming is fully complete
        //setIsThinking(false);
        // Update input placeholder *after* state update
         setTimeout(() => {
             if (textareaRef.current) {
                 textareaRef.current.placeholder = "Type your question...";
             }
         }, 0);
    }, []); // No dependencies needed

    // Example: Start streaming chat messages (using SSE)
    const streamChatMessages = useCallback(async () => {
        if (!sessionId || !token) {
            console.error("Missing session_id or mytoken. Cannot stream chat messages.");
            return;
        }
        if (eventSourceRef.current) {
            console.warn("SSE connection already open. Closing previous one.");
            closeSSE(); // Ensure only one connection is open
        }

        console.log("🚀 Initiating SSE connection for streaming response...");

        // Function to initialize/reconnect the EventSource connection
        let eventSource;
        eventSource = new EventSource(`${api_url}/api/chat-stream/${token}/${sessionId}/`);
        eventSourceRef.current = eventSource;

        eventSource.onopen = () => {
            console.log("SSE connection opened for streaming.");
        };
            
        eventSource.onmessage = (event) => {
            console.log("New SSE Messages inbound...")
            try {
                // Check for the backend's "end-of-stream" signal
                if (event.data.startsWith(':')) {
                    console.log("SSE Keepalive received"); // Log keepalive if backend sends it
                    return; // Ignore keepalive comments
                }

                const parsedData = JSON.parse(event.data.trim());

                // *** Check for end-of-stream signal from backend ***
                if (parsedData.status === 'complete') {
                    console.log("✅ SSE stream complete signal received.");
                    closeSSE();
                    return; // Stop processing after completion signal
                }

                const newMessages = JSON.parse(event.data.trim()).messages || [];
                console.log("🔹 New SSE Messages received: ", newMessages);
                    
                if (newMessages.length > 0) {
                    setAiLogs((prevLogs) => {
                        const existingMessages = new Set(prevLogs.map(msg => msg.content));
            
                        const filteredMessages = newMessages
                            .filter(msg => !existingMessages.has(msg.content))  // Avoid duplicates
                            .map(msg => ({
                                "role": msg.role.replace(/^assistant$/, agent_alias).replace(/^user$/, user_alias),
                                "content": msg.content,
                                "id": msg.id
                            }));
            
                        if (filteredMessages.length > 0) {
                            if (!isExpandedRef.current) {
                                // Increase the new message count when minimized
                                setNewMessageCount(prevCount => prevCount + filteredMessages.length);
                            }
                            scrollToBottom();
                        }
            
                        return [...prevLogs, ...filteredMessages];
                    });
                }

            } catch (error) {
                console.error("Error parsing SSE message:", error);
            }
        };

        eventSource.onerror = (error) => {
            console.error("SSE error:", error);
            // Close the connection on ANY error now, as it's meant to be short-lived
            closeSSE();
        };

        return () => {
            console.log("🔴 Closing SSE stream...");
            eventSource.close();
        }
    // *** Add dependencies for useCallback ***
    }, [sessionId, token, closeSSE]); // Include isThinking

    // Add a function to post a new user message
    const handleSend = useCallback(async () => {
        if (!userInput.trim()) return;

        // Lock the input and button
        setIsThinking(true);

        try {
            streamChatMessages();

            const data = {
                mytoken: token,        // from your parent props or cookies
                session_id: sessionId, // from your parent props or localStorage
                message: userInput,
                customer_id: customerId['customer_id']
            };

            const response = await fetch(`${api_url}/api/post-user-message/`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
                },
                body: JSON.stringify(data)
            })
            .then(setUserInput('Thinking...'))
            .then(setAiLogs((prevLogs) => [
                ...prevLogs,
                { role: user_alias, content: userInput }
            ]))
            .then(scrollToBottom())

            if (!response.ok) {
                // handle error
                console.error("Failed to post message:", response.statusText);
                setUserInput("");
                setIsThinking(false);
                adjustHeight();
                return;
            }

            const result = await response.json();
            console.log("AI Response:", result.assistant_response);

        } catch (error) {
            console.error("Error sending message to AI:", error);
            setUserInput("");
            setIsThinking(false);
            adjustHeight();
        }

        // Clear input
        setUserInput("");
        setIsThinking(false);
        adjustHeight();
    // Add dependencies
    }, [userInput, token, sessionId, customerId, streamChatMessages]);

    const handleKeyDown = (e) => {
        // Simple mobile detection via userAgent.
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    
        if (e.key === 'Enter') {
          // On desktop, send message if Enter is pressed without Shift.
          if (!isMobile && !e.shiftKey) {
            e.preventDefault();
            if (userInput.trim()) {
                handleSend();
            }
          }
          // On mobile or when Shift+Enter is pressed, allow the newline.
          // No need to call e.preventDefault() in that case.
        }
    };

    const adjustHeight = () => {
        const ta = textareaRef.current;
        if (ta) {
            ta.style.height = 'auto';
            ta.style.height = `${ta.scrollHeight}px`;
        }
    };

    useEffect(() => {
        // Fetch previous messages and then start streaming
        if (token && sessionId) {
            fetchChatMessages();
        }
        const cleanup = closeSSE();
        return cleanup;
    }, [sessionId, token, fetchChatMessages, closeSSE]);

    // Effect to listen for the custom trigger event
    useEffect(() => {
        const handleTrigger = () => {
            console.log(`Received ${TRIGGER_STREAM_EVENT} event.`);
            // Ensure the chat is visible/expanded before starting stream?
            // if (!isExpandedRef.current) {
            //   console.log("Chat not expanded, expanding first.");
            //   setIsExpanded(true); // Auto-expand the chat maybe?
            // }

            // Call startStreamingResponse directly. It has internal checks now.
            streamChatMessages();
        };

        console.log(`Adding event listener for ${TRIGGER_STREAM_EVENT}`);
        window.addEventListener(TRIGGER_STREAM_EVENT, handleTrigger);

        // Cleanup listener on component unmount
        return () => {
          console.log(`Removing event listener for ${TRIGGER_STREAM_EVENT}`);
          window.removeEventListener(TRIGGER_STREAM_EVENT, handleTrigger);
        };
    // Add dependencies: startStreamingResponse is now stable via useCallback
    }, [streamChatMessages]);

    // Render if token and sessionId exist
    if (token != null && sessionId != null) {
        
    // Render minimized chat box when not expanded
    if (!isExpanded) {
        return (
        <div className="ai-log-container-minimized"
            onClick={() => setIsExpanded(true)}
            style={{
            transition: 'transform 0.2s, box-shadow 0.2s'
            }}
            onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = "0 6px 12px rgba(0,0,0,0.4)";
            }}
            onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.3)";
            }}
        >
            Chat
            <div className="ai-log-new-message-count"
                style={{ fontWeight: newMessageCount > 0 ? 'bold' : 'normal', borderColor: newMessageCount > 0 ? '#00d3fb' : '#0a97f9'}}
            >
                <i>{newMessageCount > 5 ? newMessageCount + '!' : newMessageCount}</i>
            </div>
            <img 
                src="/assistant.webp"  // replace with your logo URL
                alt="Hai Byte Shark Assistant"
                className="chat-avatar"
                style={{
                    position: 'absolute',  // Position relative to the container
                    left: '-75px',         // Adjust as needed
                    bottom: '-65px',          // Adjust as needed
                    width: '70px',         // Optional: define size
                    height: '70px'
                  }}
            />
        </div>
        );
    }
    
        return (
                <div className="ai-log-container">

                    {/* Header fixed at the top */}
                    <div className="ai-log-header">
                        <h5>{agent_alias}, at your service!</h5>
                        <button className="btn btn-outline-secondary" onClick={() => setIsExpanded(false)} style={{ fontSize: '14px' }}>Minimize</button>
                    </div>

                    {/* Chat log container */}
                    <div className="ai-log" 
                    ref={aiLogRef}
                    >
                    {aiLogs.length > 0 && aiLogs.map((log, index) => (
                    <div 
                        key={index}
                        style={{
                            display: "flex",
                            justifyContent: log.role === agent_alias ? "flex-start" : "flex-end",
                            alignItems: "center",
                            marginBottom: "8px",
                            paddingTop: "16px"
                        }}
                    >
                        {/* Conditionally render the assistant logo if the role is the assistant */}
                        {log.role === agent_alias && (
                        <img 
                            src="/assistant_bg_blue.webp"  // replace with your logo URL
                            alt="Hai Byte Shark Assistant"
                            className="chat-avatar"
                        />
                        )}
                        <div
                            style={{
                                fontStyle: log.role === user_alias ? "italic" : "normal",
                                backgroundColor: "#011f2a",
                                padding: "8px 12px",
                                borderRadius: "18px",
                                display: "inline-block",
                                maxWidth: "60%",
                                boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                                border: log.role === agent_alias ? "1px solid #0484f1" : "1px solid #080" // Green for user, Blue for AI
                            }}
                        >
                            <MarkdownMessage role={log.role} content={log.content} />
                        </div>
                    </div>
                    ))}
                    </div>
                    
                    {/* Input box at the bottom */}
                    <div className="ai-log-input">
                        <textarea
                        ref={textareaRef}
                        type="text"
                        className="form-control"
                        value={userInput}
                        onChange={(e) => {
                            setUserInput(e.target.value)
                            adjustHeight();
                        }}
                        onKeyDown={(e) => {
                            handleKeyDown(e);
                        }}
                        style={{ width: '100%', maxHeight: '800px', padding: '8px', marginRight: '8px', borderRadius: '4px', border: '1px solid #ccc', overflow: 'hidden', resize: 'none' }}
                        placeholder={isThinking ? "Thinking..." : "Type your question..."}
                        rows={3}
                        disabled={isThinking}
                        />
                        <button type="button" className="btn btn-outline-primary" onClick={handleSend} disabled={isThinking}>
                            {isThinking ? "Waiting..." : "Send"}
                        </button>
                    </div>
                </div>
        );
    } else {
        return(<div></div>)
    }
};

export default AiLogContainer;
