// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import MessageWarning from './MessageWarning';

const api_url = process.env.REACT_APP_API_URL;

export const UpgradeBar = () => {

    const [token, setToken, removeToken] = useCookies(['mytoken'])
    let history = useHistory()
    const [messageWarning, setMessageWarning] = useState('')

    // Subscription status
    const [subscriptionStatus, setSubscriptionStatus] = useState('')

    useEffect(() => {
            
        if (token['mytoken'] !== undefined && token['mytoken'] !== "undefined") {
            // check subscription status
            const data0 = {
                'mytoken': token['mytoken']
            }
            fetch(`${api_url}/api/get-subscription-status/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify(data0),
                
            })
            .then(resp0 => resp0.json())
            .then(resp0 => {
                if (resp0 !== null) {
                    setSubscriptionStatus(resp0);
                }
            })
            .catch(error => console.log(error))
            if (subscriptionStatus === 'trialEnded') {
                setMessageWarning("Your trial has ended. Please contact sales (sales@haibyte.com) to set up a payment plan and  continue using the tool.")
            } else if (subscriptionStatus) {
                if (subscriptionStatus === 'trial') {
                    setMessageWarning("Your 7 day free Hai Byte Pro trial is currently active. To continue using Hai Byte after the trial, subscribe to a paid plan by contacting sales (sales@haibyte.com).")
                }
            }
        }
        
    }, [token, subscriptionStatus])

    // Handling button clicks
    const onClick = e => {
        history.push('/campaigns')

    }


    return (

        <div className="container mt-4">
            {messageWarning ? <MessageWarning msg={messageWarning} /> : null}
        </div>
    )
}

export default UpgradeBar;