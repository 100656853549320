// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, {useState, useEffect} from 'react';
import APIService from '../APIService';
import {useCookies} from 'react-cookie';
import {useHistory} from 'react-router-dom';
import Message from './Message';

//const api_url = 'http://localhost:8000';
const api_url = process.env.REACT_APP_API_URL;
console.log("API_URL:");
console.log(api_url);

function Login() {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [token, setToken] = useCookies(['mytoken'])
    const [message, setMessage] = useState('')
    let history = useHistory()

    // Ping backend to wake it up and reduce response time from the first user API request
    fetch(`${api_url}/api/ready/`, {
        method: 'GET'
    }).then(response => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`)
        }
    })
    .catch(error => {
        console.error('Fetch error: ', error)
    })

    // if user has token, redirect user to googleads page
    useEffect (() => {
        if(token['mytoken'] && token['mytoken'] !== 'undefined') {
            // Send HB API request to update a property
            let email = username
            let property = "last_logged_in"
            let value = new Date(new Date().setUTCHours(0, 0, 0, 0))
            fetch(`${api_url}/api/update-hb-property/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify({email, property, value})
            })
            setMessage('')
            // Redirect user
            history.push('/googleads')
        }
    }, [token, history, username])
    
    const loginBtn = () => {
        setMessage('Logging in... It can take a few seconds.')
        APIService.LoginUser({username, password})
        .then(resp => setToken('mytoken', resp.token))
        .catch(error => console.log(error))

    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            // Send message if Enter is pressed without Shift.
            e.preventDefault();
            if (username && password) {
                loginBtn();
            }
        }
    };

    return (
        <div>
            <br/>
            <h4 className="display-4 text-left mb-4 title">Login to your account</h4>

            <br />
            {message ? <Message msg={message} /> : null}

            <div className="mb-3">

                <label htmlFor="username" className= "form-label">Email or username</label>
                <input type="text" className="form-control" id="username" placeholder="Please Enter Username" 
                value={username} onChange= {e => setUsername(e.target.value)} onKeyDown={(e) => {
                    handleKeyDown(e);
                }}/>
            </div>

            <div className="mb-3">

                <label htmlFor="password" className= "form-label">Password</label>
                <input type="password" className="form-control" id="password" placeholder="Please Enter Password" 
                value={password} onChange= {e => setPassword(e.target.value)} onKeyDown={(e) => {
                    handleKeyDown(e);
                }}/>
            </div>

            <button onClick={loginBtn} className="btn btn-success">Login</button>

            <div className="mb-3">
                <br/>
                <p>Don't have an account? <button className="btn btn-outline-primary" onClick={() => window.open("https://www.haibyte.com/", "_blank")}>Signup at haibyte.com</button></p>
            </div>

            <div className='mt-4' align="center">
                <img src="/mascot_bg_blue.webp" loading="lazy" alt="Hai Byte shark mascot image" class="image" />
            </div>

        </div>
    )
}

export default Login