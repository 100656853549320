// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useState } from 'react';
import {useCookies} from 'react-cookie';
import {useHistory} from 'react-router-dom';


const LearnMore = () => {

    const [token, setToken, removeToken] = useCookies(['mytoken'])
    const [refreshToken, setRefreshToken, removeRefreshToken] = useCookies(['refreshToken'])

    let history = useHistory()

    // Manage state of component so it shows when user clicks it
    const [showCases, setShowCases] = useState(false)
    const onClick = () => setShowCases(showCases ? false : true)

    // Component that has the list of use cases
    const Cases = () => (
        
        <div className="card">
            <div className="card-body">
            <p className="mb-0" align="left" >
                    1. Say goodbye to manual adjustments—our technology optimizes campaigns <strong>365 days in a year</strong> so you can focus on strategic decisions.
                <br/>
                    2. Let the technology do the work and enjoy <strong>improved</strong> ROI without the cost of hiring an agency.
                <br/>
                    3. Incrementality insights powered by our MMM help you <strong>cut wasted spend</strong> and grow your business faster than ever—and <strong>make more money</strong>.
                <br/>
                    4. See which channels your Performance Max campaigns are spending on to guide your budgeting decisions.
                <br/>
                    5. Easily see how your competitors' auction activity is trending.
                <br/>
                    6. Get AI recommendations to grow your business further.
            </p>
            </div>
        </div>
        
    )

    // START button
    const goStart = () => {
        // if there is a refresh token in the cookies,
        // send user to the Accounts page.
        if(refreshToken['refreshToken']) {
            history.push('/googleads-accounts')
        }
        // if no refresh token but yes mytoken,
        // send user to Google Ads page.
        else if(token['mytoken']) {
            history.push('/googleads')
        }
        // if neither, this means user is not logged in yet,
        // so push user to the login or signup page.
        else {
            history.push('/login')
        }  
    }

    // HOME button
    const goHome = () => {
        history.push('/')  
    }

    return (
        
    <div className="container mt-4">
        <br/>
        
        <h4 className="display-4 text-left mb-4 title">
            Why use Hai Byte?
        </h4>

        <br/>
        <br/>


        <p className="mb-0" align="left" >
        Without continuous optimization and rigorous analysis, valuable opportunities to improve ad performance, 
        target relevant keywords and enhance ad copy are missed. This results in wasted ad spend and stagnating growth. 
        Automated AI-powered campaign optimization improves the efficiency of your campaigns, getting you
        <strong> better results with the same budget</strong>.
        <br/>
        <br/>
        When you sign up for Hai Byte, you’ll get access to a <strong>7 day free trial</strong> so that you can get familiar with 
        what we can do for you. You'll link your Google Ads with Hai Byte and choose which accounts and campaigns you 
        want to optimize automatically with Hai Byte. We only optimize the accounts and campaigns that you select, 
        so you can initially test the tool with just a few of them to get a feel for how it works and expand automated 
        optimization to the rest of the campaigns once you feel comfortable with that.
        <br/>
        <br/>
        </p>
        
        <button type="button" className="btn btn-link" 
        onClick={onClick} 
        style={{ 
            "textDecoration": "none", 
            "font":"gotham-rounded-bold", 
            fontSize:'20px' }}>
                See key benefits   
                <i className="fas fa-chevron-down fa-fw"></i>
        </button>
        { showCases ? <Cases /> : null }
        
        <div className='mt-4' align="center">
            <br></br>
            
            <button type="button" className="btn btn-success btn-block" style={{margin:'10px'}}
            onClick={goStart}>GET STARTED</button>
           
            <button type="button" className="btn btn-outline-primary btn-block" style={{margin:'10px'}}
            onClick={goHome}>HOME</button>

<div className='mt-4' align="center">
                <img src="/mascot_bg_blue.webp" loading="lazy" alt="Hai Byte shark mascot image" class="image" />
            </div>
            
        </div>
        
    </div>
)}

export default LearnMore;