// fetch requests use h'ttp://localhost:8000 in local testing, h'ttps://google-ads-backend-test-qgiq37b64q-lz.a.run.app on the cloud
const api_url = process.env.REACT_APP_API_URL;

export default class APIService {
    static UpdateArticle(article_id, body, token) {
        return fetch(`${api_url}/api/articles/${article_id}/`, {
            'method': 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(body)
        }).then(resp => resp.json())
    }

    static CreateArticle(body, token) {
        return fetch(`${api_url}/api/articles/`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(body)
        }).then(resp => resp.json())
    }

    static DeleteArticle(article_id, token) {
        return fetch(`${api_url}/api/articles/${article_id}/`, {
            'method': 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
    }

    static LoginUser(body) {
        return fetch(`${api_url}/auth/`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(resp => resp.json())
    }

    static RegisterUser(body) {
        return fetch(`${api_url}/api/users/`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(resp => resp.json())
    }


}