// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useState, useEffect, Fragment } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import Message from './Message'
import MessageWarning from './MessageWarning'
import MessageSuccess from './MessageSuccess'
import {
    Chart as ChartJS,
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Legend
} from "chart.js";
import { Line } from 'react-chartjs-2';
import {sumByGroup, groupByKeys, sumValues} from './Utils';

ChartJS.register(
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Legend
)

const api_url = process.env.REACT_APP_API_URL;

const PmaxPerformance = () => {

    const [token, setToken, removeToken] = useCookies(['mytoken'])
    const [refreshToken, setRefreshToken, removeRefreshToken] = useCookies(['refreshToken'])
    let history = useHistory()

    // store campaign info from the api
    const [campaignInfo, setCampaignInfo] = useState([])

    const [customerId, setCustomerId, removeCustomerID] = useCookies(['customer_id'])
    const [currency, setCurrency, removeCurrency] = useCookies(['currency'])
    const [campaignId, setCampaignId, removeCampaignID] = useState("All")

    // store billing status from the api
    const [billingStatus, setBillingStatus] = useState('APPROVED')


    // to filter the table
    const [status, setStatus] = useState("All but removed")

    // to filter the query to be sent to the api
    const [date, setDate] = useState("ALL_TIME")

    // messages to inform users
    const [message, setMessage] = useState(' Fetching your data... It will take a few seconds.')
    const [messageWarning, setMessageWarning] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')

    const [metric1, setMetric1] = useState('conversions')
    const [metric2, setMetric2] = useState('cost')

    // Subscription status
    const [subscriptionStatus, setSubscriptionStatus] = useState('')

    // success message saying campaign was created
    // when user is redirected here after successfully creating a campaign
    // const [successMessage, setSuccessMessage, removeSuccessMessage] = useCookies(['successMessage'])



    // if there is no mytoken in the cookie, redirect user to the home page (denying access)
    useEffect(() => {
        if (!token['mytoken'] || token['mytoken'] === 'undefined') {
            // history.push('/')
            window.location.href = '/'
        }
    }, [token])

    // if there is a customer_id in the cookies
    // send it to the backend with the refreshToken
    // where they will be used to get the campaigns info associated with that customer_id and token
    useEffect(() => {
        if (customerId) {

            // tell user you are fetching their data
            setMessage(' Fetching your data... It can take a few seconds.');

            // check subscription status
            const data0 = {
                'mytoken': token['mytoken']
            }

            fetch(`${api_url}/api/get-subscription-status/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify(data0),
                
            })
            .then(resp0 => resp0.json())
            .then(resp0 => {
                if (resp0 !== null) {
                    setSubscriptionStatus(resp0);
                    if (subscriptionStatus === 'trialEnded') {
                        setMessage('')
                    } else if (subscriptionStatus === 'tier1') {
                        setMessageWarning("Your current subscription does not include access to Performance Analysis & incrementality reporting. Please contact support (email: support@haibyte.com) to upgrade your subscription and get access.")
                        setMessage('')
                    } else if (subscriptionStatus) {
                        if (subscriptionStatus === 'trial') {
                            setMessageWarning("This section is included in Hai Byte Pro subscription. You have access to it during your Hai Byte Pro trial.")
                        }
                        // data to send to the backend
                        const data = {
                            'mytoken': token['mytoken'],
                            'refreshToken': refreshToken['refreshToken'],
                            'customer_id': customerId['customer_id'],
                            'date_range': date
                        }
        
                        fetch(`${api_url}/api/get-pmax-by-channel/`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Token ${token['mytoken']}`
                            },
                            body: JSON.stringify(data),
        
                        })
                            .then(resp => resp.json())
                            .then(resp => {
                                if (resp !== null) {
                                    // console.log(resp);
                                    setCampaignInfo(resp);
                                    setMessage('')
                                } else if (resp === null) {
                                    // console.log(resp);
                                    setMessage('');
                                    setMessageWarning('You do not have campaigns. Create a campaign that can start showing ads within one day.')
        
                                }
                            })
                            .catch(error => console.log(error))
                    }
                }
            })
            .catch(error => console.log(error))


        }
    }, [customerId, refreshToken, token, date, subscriptionStatus])

    // if campaignInfo object has data, delete the 'fetching data' message
    // and get the billing status
    useEffect(() => {
        if (campaignInfo.length > 0) {
            setMessage('');

            // data to send to the backend
            const data2 = {
                'mytoken': token['mytoken'],
                'refreshToken': refreshToken['refreshToken'],
                'customer_id': customerId['customer_id'],
                'date_range': "using same model of reporting"
            }

            fetch(`${api_url}/api/get-billing/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify(data2),

            })
                .then(resp => resp.json())
                .then(resp => {
                    if (resp !== null) {
                        console.log(resp);
                        setBillingStatus(resp);
                    } else if (resp === null) {
                        console.log(resp);
                        setBillingStatus(resp);

                    }
                })
                .catch(error => {
                    console.log(error)
                    setMessage('')
                    setMessageWarning(toString(error))
                })
        }
    }, [campaignInfo, customerId, refreshToken, token])

    // filter campaigns by date
    const onChangeDate = (e) => {
        setDate(e.target.value);
        setCampaignInfo([])

    }

    const onChangeMetric1 = (e) => {
        setMetric1(e.target.value)

    }
    const onChangeMetric2 = (e) => {
        setMetric2(e.target.value)

    }

    // go back button
    const goAccountsList = () => {
        // if user has refresh token cookie, take them to Accounts page
        if (refreshToken['refreshToken']) {
            history.push('/googleads-accounts')
        // if not, taking them to the home page
        } else {
            history.push('/')
        }
        
    }

    const onClick = e => {
        const campaignID = e.currentTarget.id
        if (campaignId === campaignID) {
            setCampaignId("All");
        } else {
            setCampaignId(campaignID);
        }

    }

    const campaigns = () => {
        history.push('/campaigns')
    }

    const visualized_series = sumByGroup(campaignInfo, metric1 + '_shop', 'week', false, 'campaign_id', campaignId)
    const visualized_series1 = sumByGroup(campaignInfo, metric1 + '_ser', 'week', false, 'campaign_id', campaignId)
    const visualized_series2 = sumByGroup(campaignInfo, metric1 + '_dv', 'week', false, 'campaign_id', campaignId)
    const visualized_series3 = sumByGroup(campaignInfo, metric2 + '_shop', 'week', false, 'campaign_id', campaignId)
    const visualized_series4 = sumByGroup(campaignInfo, metric2 + '_ser', 'week', false, 'campaign_id', campaignId)
    const visualized_series5 = sumByGroup(campaignInfo, metric2 + '_dv', 'week', false, 'campaign_id', campaignId)
    const visualized_label = sumByGroup(campaignInfo, 'week', 'week', true)

    const chart_data = {
        labels: visualized_label,
        datasets: [
            {
                label: 'Shopping ' + metric1,
                data: visualized_series,
                backgroundColor: '#041d33',
                borderColor: '#041d33',
                pointBorderColor: '#041d33',
                yAxisID: 'y'
            },
            {
                label: 'Search ' + metric1,
                data: visualized_series1,
                backgroundColor: '#0484f1',
                borderColor: '#0484f1',
                pointBorderColor: '#0484f1',
                yAxisID: 'y'
            },
            {
                label: 'Display & video ' + metric1,
                data: visualized_series2,
                backgroundColor: '#e55100',
                borderColor: '#e55100',
                pointBorderColor: '#e55100',
                yAxisID: 'y'
            }
        ]
    }

    const chart_data2 = {
        labels: visualized_label,
        datasets: [
            {
                label: 'Shopping ' + metric2,
                data: visualized_series3,
                backgroundColor: '#041d33',
                borderColor: '#041d33',
                pointBorderColor: '#041d33',
                yAxisID: 'y'
            },
            {
                label: 'Search ' + metric2,
                data: visualized_series4,
                backgroundColor: '#0484f1',
                borderColor: '#0484f1',
                pointBorderColor: '#0484f1',
                yAxisID: 'y'
            },
            {
                label: 'Display & video ' + metric2,
                data: visualized_series5,
                backgroundColor: '#e55100',
                borderColor: '#e55100',
                pointBorderColor: '#e55100',
                yAxisID: 'y'
            }
        ]
    }

    const chart_options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: 'Results over time. Included campaigns: ' + campaignId,
            },
            legend: {
                display: true,
                position: 'left'
            }
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                min: Math.min(visualized_series, visualized_series1, visualized_series2),
                max: Math.max(visualized_series, visualized_series1, visualized_series2)
            },
        }
    }

    const chart_options2 = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: 'Results over time. Included campaigns: ' + campaignId,
            },
            legend: {
                display: true,
                position: 'left'
            }
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                min: Math.min(visualized_series3, visualized_series4, visualized_series5),
                max: Math.max(visualized_series3, visualized_series4, visualized_series5)
            },
        }
    }

    return (

        <div className="container mt-4">

            <br />
            <h4 className="display-4 text-left mb-4 title" >
                PMax analysis
            </h4>

            <br />
            <button type="button" className="btn btn-link" name="go back"
                onClick={goAccountsList} >
                <i className="fas fa-arrow-left fa-2x"></i>
            </button>
            <br />
            <br />
            {/* if user does not have billing set up yet */}
            {billingStatus === 'APPROVED' ? null :
                <Fragment>
                    <div className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                        style={{
                            display: 'inline-block',
                            fontSize: '16px'
                        }}>
                        <i className="fas fa-exclamation-triangle fa-fw"
                            style={{ marginRight: '5px' }}></i>
                        Your account cannot show ads.
                        To start running your ads,
                        <a href="https://ads.google.com/home/" className="alert-link"
                            target="_blank" rel="noopener noreferrer"> CLICK HERE </a>
                        to enter your billing information.
                        This link will open a new tab with Google Ads platform.
                        Go to Settings in the top menu,
                        and click on Billing & payments.
                        When you finish,
                        close that tab to continue working on Hai Byte.

                    </div>
                </Fragment>}
            {/* if user has campaigns, show this message */}
            {campaignInfo.length > 0 ?
                <Fragment>
                    <p>See which channels your PMax campaigns are spending their budget in.
                        <br />
                        <br />
                        This report is in early testing stage - please report if you notice any bugs or have improvement ideas.
                    </p>
                </Fragment> :
                // if user has zero campaigns yet, show this message
                <Fragment>
                    <p>Oh! It seems you do not have ads running on Google yet.
                        Create a campaign to start selling more!
                    </p>
                </Fragment>}


            <br />
            
            {/* <div className="col-6"> */}
                    {/* <button onClick={create} className="btn btn-success">Create campaign</button> */}
                    {/* <button onClick={campaigns} className="btn btn-success">Auto-optimization settings</button> */}
            {/* </div> */}

            {/* <br /> */}

            {message ? <Message msg={message} /> : null}
            {messageWarning ? <MessageWarning msg={messageWarning} /> : null}
            {messageSuccess ? <MessageSuccess msg={messageSuccess} /> : null}
            <br />
            <br />

            <div className="container">
                <div className="col-sm">

                    <p><i className="fas fa-filter"></i>  Select metrics to visualize</p>

                    <div className="btn-group">

                        <select className="form-select form-select-sm" onChange={onChangeMetric1} value={metric1} aria-label="Filter table by campaign status">

                            <option value="">None</option>
                            <option value="cost">Cost</option>
                            <option value="clicks">Clicks</option>
                            <option value="conversions">Conversions</option>
                            <option value="conversions_value">Conversion value</option>
                            
                        </select>
                        <select className="form-select form-select-sm" onChange={onChangeMetric2} value={metric2} aria-label="Filter table by campaign status">

                            <option value="">None</option>
                            <option value="cost">Cost</option>
                            <option value="clicks">Clicks</option>
                            <option value="conversions">Conversions</option>
                            <option value="conversions_value">Conversion value</option>

                        </select>
                    </div>

                </div>

                <div className="scroll-wrapper">
                    <div className="row">
                        <div className="chart" align='left' style={{width: '50%'}}>
                            <Line options={chart_options} data={chart_data} />
                        </div>
                        <div className="chart" align='right' style={{width: '50%'}}>
                            <Line options={chart_options2} data={chart_data2} />
                        </div>
                    </div>
                </div>

                <div className="col-sm" align='left'>
                    <p><i className="fas fa-filter"></i>  Filter by date</p>
                    <div className="btn-group" >
                        <select className="form-select form-select-sm" onChange={onChangeDate} value={date} aria-label="Filter table by date">
                            <option value="TODAY">Today</option>
                            <option value="YESTERDAY">Yesterday</option>
                            <option value="THIS_WEEK_SUN_TODAY">This week (Sun - Today)</option>
                            <option value="LAST_7_DAYS">Last 7 days</option>
                            <option value="LAST_14_DAYS">Last 14 days</option>
                            <option value="THIS_MONTH">This month</option>
                            <option value="LAST_30_DAYS">Last 30 days</option>
                            <option value="LAST_MONTH">Last month</option>
                            <option value="ALL_TIME">All time</option>
                        </select>
                    </div>
                </div>

                <div className="scroll-wrapper-dark">
                    <table className="table table-bordered table-hover table-responsive">
                        <thead className="thead-light" >
                            <tr key="accounts_table" style={{ textAlign: 'center', verticalAlign: 'top' }}>

                                <th key="campaign_name" scope="col">Campaign name</th>
                                <th key="cost" scope="col">Total cost {currency['currency']}</th>
                                <th key="cost_shop" scope="col">Shopping cost {currency['currency']}</th>
                                <th key="cost_dv" scope="col">Display & video cost {currency['currency']}</th>
                                <th key="cost_ser" scope="col">Search cost {currency['currency']}</th>
                                {/*
                                <th key="impressions" scope="col">Total impr.</th>
                                <th key="impressions_shop" scope="col">Shopping impr.</th>
                                <th key="impressions_dv" scope="col">Display & video impr.</th>
                                <th key="impressions_ser" scope="col">Search impr.</th>
                                */}
                                <th key="clicks" scope="col">Total clicks</th>
                                <th key="clicks_shop" scope="col">Shopping clicks</th>
                                <th key="clicks_dv" scope="col">Display & video clicks</th>
                                <th key="clicks_ser" scope="col">Search clicks</th>
                                <th key="conversions" scope="col">Total conversions</th>
                                <th key="conversions_shop" scope="col">Shopping conversions</th>
                                <th key="conversions_dv" scope="col">Display & video conversions</th>
                                <th key="conversions_ser" scope="col">Search conversions</th>
                                <th key="conversion_value" scope="col">Total conversion value {currency['currency']}</th>
                                <th key="conversions_value_shop" scope="col">Shopping conversion value {currency['currency']}</th>
                                <th key="conversions_value_dv" scope="col">Display & video conversion value {currency['currency']}</th>
                                <th key="conversions_value_ser" scope="col">Search conversion value {currency['currency']}</th>

                            </tr>
                        </thead>

                        <tbody>
                            {campaignInfo.length > 0 && groupByKeys(campaignInfo, ['campaign_id', 'campaign_name', 'campaign_type', 'bidding_strategy_type']).map(item => {
                                return (

                                    <tr key={item.campaign_id} id={item.campaign_id} value={item.campaign_id}
                                        onClick={onClick}
                                        style={{
                                            textAlign: 'center', cursor: 'pointer',
                                            display: item.campaign_id ? '' : 'none'
                                        }}>

                                        <td> {item.campaign_name}</td>
                                        <td> {String(item.cost.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.cost_shop.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.cost_dv.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.cost_ser.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        {/*
                                        <td> {String(item.impressions.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.impressions_shop.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.impressions_dv.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.impressions_ser.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        */}
                                        <td> {String(item.clicks.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.clicks_shop.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.clicks_dv.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.clicks_ser.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.conversions.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.conversions_shop.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.conversions_dv.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.conversions_ser.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.conversions_value.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.conversions_value_shop.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.conversions_value_dv.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>
                                        <td> {String(item.conversions_value_ser.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</td>

                                    </tr>

                                )

                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
        </div>
    )
}

export default PmaxPerformance;