import React, { useState, useEffect, Fragment } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import Message from './Message';
import MessageWarning from './MessageWarning';
import MessageError from './MessageError';
import { checkForTrue } from './Utils';

const api_url = process.env.REACT_APP_API_URL;

const AccessibleCustomers = () => {
    const [token, setToken] = useCookies(['mytoken']);
    const [refreshToken] = useCookies(['refreshToken']);
    let history = useHistory();
    const [accountInfo, setAccountInfo] = useState([]);
    const [customerId, setCustomerId, removeCustomerID] = useCookies(['customer_id']);
    const [currency, setCurrency] = useCookies(['currency']);
    const [cidStatus, setCidStatus] = useCookies(['cidStatus']); 
    const [Url, setUrl] = useState('');
    const [cidStatusNew, setCidStatusNew] = useState('');
    const [message, setMessage] = useState(' Fetching your data... It will take a few seconds.');
    const [messageWarning, setMessageWarning] = useState('');
    const [messageError, setMessageError] = useState('');
    const [subscriptionStatus, setSubscriptionStatus] = useState('');

    // New state to track current navigation level.
    // When null, we are at top-level.
    const [currentManagerId, setCurrentManagerId] = useState(null);
    // Optionally, a stack to support multi-level navigation.
    const [accountStack, setAccountStack] = useState([]);

    // Check if user is authenticated
    useEffect(() => {
        if (!token['mytoken'] || token['mytoken'] === 'undefined') {
            window.location.href = '/';
        }
    }, [token]);

    // Helper function to fetch accounts based on current level.
    const fetchAccounts = (managerId) => {
        // Determine which API endpoint to call.
        const endpoint = managerId 
            ? `${api_url}/api/get-accounts-by-manager/`
            : `${api_url}/api/get-accounts/`;

        const data = {
            'mytoken': token['mytoken'],
            'refreshToken': refreshToken['refreshToken']
        };

        // If a managerId is provided, add it to the request payload.
        if (managerId) {
            data['manager_id'] = managerId;
        }

        fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['mytoken']}`
            },
            body: JSON.stringify(data),
        })
        .then(resp => resp.json())
        .then(resp => {
            console.log(resp);
            setAccountInfo(resp);
            //removeCustomerID('customer_id');
        })
        .catch(error => {
            console.log(error);
            setMessageError('Error fetching accounts. Please try again.');
            //removeCustomerID('customer_id');
        });
    };

    // On mount, fetch top-level accounts if refresh token is available.
    useEffect(() => {
        if (refreshToken['refreshToken']) {
            fetchAccounts(null);
            // Also check subscription status as before...
            const data0 = { 'mytoken': token['mytoken'] };
            fetch(`${api_url}/api/get-subscription-status/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify(data0),
            })
            .then(resp0 => resp0.json())
            .then(resp0 => {
                if (resp0 !== null) {
                    setSubscriptionStatus(resp0);
                    if (resp0 !== 'trialEnded') {
                        setMessage(' Fetching your data... It can take a few seconds.');
                    } else {
                        setMessage('');
                    }
                }
            })
            .catch(error => console.log(error));
        }
    }, [token, refreshToken]);

    // When accountInfo is loaded, remove the loading message.
    useEffect(() => {
        if (accountInfo.length > 0) {
            setMessage('');
        }
    }, [accountInfo]);

    // Modified onClick handler for table rows.
    const handleAccountClick = e => {
        const cusID = e.currentTarget.id;
        const accountType = e.currentTarget.getAttribute('data-account-type');

        if (accountType === 'Manager') {
            // For manager accounts, navigate to child accounts.
            // Optionally push the current manager onto a stack.
            setAccountStack(prev => [...prev, currentManagerId]);
            setCurrentManagerId(cusID);
            fetchAccounts(cusID);
            setMessage(' Fetching your data... It can take a few seconds.');
        } else {
            // For client accounts, handle selection as before.
            setCustomerId("customer_id", cusID);
            setCurrency("currency", e.currentTarget.getAttribute("currency"));
            const automationStatus = e.currentTarget.getAttribute("automationstatus");
            if (cidStatusNew) {
                setCidStatus("cidStatus", cidStatusNew);
            } else {
                setCidStatus("cidStatus", automationStatus);
            }
            // (Optional) If account is not linked to your Manager, call the linking API here.
            history.push('/performance');
        }
    };

    // Navigation: go back to previous level.
    const goBack = () => {
        if (accountStack.length > 0) {
            const previousManagerId = accountStack[accountStack.length - 1];
            setAccountStack(prev => prev.slice(0, prev.length - 1));
            setCurrentManagerId(previousManagerId);
            fetchAccounts(previousManagerId);
        } else {
            // If no parent level, revert to top-level.
            setCurrentManagerId(null);
            fetchAccounts(null);
        }
        setMessage(' Fetching your data... It can take a few seconds.');
    };

    // Navigation: go to top-level view.
    const goToTopLevel = () => {
        setCurrentManagerId(null);
        setAccountStack([]);
        fetchAccounts(null);
        setMessage(' Fetching your data... It can take a few seconds.');
    };

    // Existing handlers for reconnect and checkbox change remain unchanged.
    const authenticateGoogle = () => {
        fetch(`${api_url}/api/connect/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['mytoken']}`
            }
        })
        .then(response => response.text())
        .then(text => {
            console.log(text);
            setUrl(text);
        })
        .catch(error => console.log(error));
    };

    useEffect(() => {
        if (Url) {
            window.location.href = Url;
        }
    }, [Url]);

    const createAccount = () => {
        history.push('/create-google-ads-account');
    };

    const handleChange = (item, e) => {
        let isChecked = e.target.checked;
        let customer_id = item.customer_id;
        const data = {
            'refreshToken': refreshToken['refreshToken'],
            'customer_id': customer_id,
            'process_automation': isChecked
        };
        fetch(`${api_url}/api/get-customer-id/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['mytoken']}`
            },
            body: JSON.stringify(data),
        })
        .then(response => {
            response.text().then(text => {
                console.log(text);
                setCidStatusNew(""+isChecked);
            });
        })
        .catch(error => console.log(error));
    };

    return (
        <div className="container mt-4">
            <br />
            <h4 className="display-4 text-left mb-4 title">Google Ads accounts</h4>
            <br />
            <p>
                Please check the boxes for the accounts that you want to optimize automatically with HaiByte.
                For campaign details or automatic optimization, select a client account.
            </p>
            <br />
            {message && <Message msg={message} />}
            {messageWarning && <MessageWarning msg={messageWarning} />}
            {messageError && (
                <Fragment>
                    <MessageError msg={messageError} />
                    <div className="container" align="left">
                        <div className="col-6">
                            <button onClick={authenticateGoogle} className="btn btn-success btn-sm">
                                Reconnect to Google Ads
                            </button>
                        </div>
                    </div>
                    <br />
                </Fragment>
            )}
            {checkForTrue(accountInfo, 'process_automation_status') > 0 ? (
                <Fragment>
                    <p><strong>Auto-optimization enabled</strong> for {checkForTrue(accountInfo, 'process_automation_status')} accounts</p>
                    <br />
                </Fragment>
            ) : (
                <Fragment>
                    <p><strong>Auto-optimization not enabled</strong> for any accounts</p>
                    <br />
                </Fragment>
            )}
            {/* Navigation buttons: show if not at top-level */}
            {currentManagerId && (
                <div className="mb-3">
                    <button onClick={goBack} className="btn btn-secondary btn-sm">Back</button>
                    <button onClick={goToTopLevel} className="btn btn-secondary btn-sm ml-2">Top Level</button>
                </div>
            )}
            <div className="scroll-wrapper-dark">
                <table className="table table-bordered table-hover table-responsive">
                    <thead className="thead-light">
                        <tr style={{ textAlign: 'center', verticalAlign: 'top' }}>
                            <th>Auto-optimize</th>
                            <th>Account name</th>
                            <th>Account type</th>
                            <th>Account status</th>
                            <th>Customer ID</th>
                            <th>Time zone</th>
                            <th>Currency</th>
                            <th>Billing status</th>
                            <th>Manager ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accountInfo.map(item => (
                            <tr key={item.customer_id}
                                id={item.customer_id}
                                data-account-type={item.account_type}
                                data-manager-id={item.manager_account_linked ? item.manager_account_linked : item.customer_id}
                                onClick={handleAccountClick}
                                currency={item.currency}
                                automationstatus={""+item.process_automation_status}
                                style={{ textAlign: 'center', cursor: 'pointer' }}>
                                <td onChange={e => handleChange(item, e)} onClick={(e) => e.stopPropagation()}>
                                    <input type="checkbox" defaultChecked={item.process_automation_status} />
                                </td>
                                <td>{item.description}</td>
                                <td>{item.account_type}</td>
                                <td>{item.status}</td>
                                <td>{item.customer_id}</td>
                                <td>{item.time_zone}</td>
                                <td>{item.currency}</td>
                                <td>{item.billing_status}</td>
                                <td>{(item.manager_account_linked === "0") ? "No Manager Linked" : item.manager_account_linked}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <br />
        </div>
    );
};

export default AccessibleCustomers;
