import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Route, Switch, BrowserRouter, } from 'react-router-dom';
import {useCookies} from 'react-cookie'
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Home from './components/Home';
import LearnMore from './components/LearnMore';
//import Contact from './components/Contact';
import GoogleAds from './components/GoogleAds';
import NavBar from './components/NavBar';
import { UpgradeBar } from './components/UpgradeBar';
import AiLogContainer from './components/AiLogContainer';
import About from './components/About';
import Login from './components/Login';
import Register from './components/Register';
import ScrollToTop from './components/ScrollToTop';
import AccessibleCustomers from './components/AccessibleCustomers';
import Campaigns from './components/Campaigns';
import Recommendations from './components/Recommendations';
import CreateCampaign from './components/CreateCampaign';
import KeywordThemes from './components/KeywordThemes';
import WriteSmartAd from './components/WriteSmartAd';
import Location from './components/Location';
import Budget from './components/Budget';
import CreateAccount from './components/CreateAccount';
import EditCampaign from './components/EditCampaign';
import CampaignPerformance from './components/CampaignPerformance';
import PmaxPerformance from './components/PmaxPerformance';
import NotFound from './components/NotFound';
import ErrorBoundary from './components/ErrorBoundary';
import { v4 as uuidv4 } from "uuid";
import "./App.css";

const App = () => {

  const [token, setToken, removeToken] = useCookies(['mytoken'])
  const sessionKey = useMemo(() => `token_${token['mytoken']}_session_id`, [token['mytoken']]);
  const [sessionId, setSessionId] = useState(() => {
    return localStorage.getItem(sessionKey) || uuidv4();  // ✅ Load from storage or generate new
  });

  // When the sessionKey changes (i.e. when token changes), update sessionId accordingly.
  useEffect(() => {
    const storedSessionId = localStorage.getItem(sessionKey);
    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      const newSessionId = uuidv4();
      setSessionId(newSessionId);
      localStorage.setItem(sessionKey, newSessionId);
    }
  }, [sessionKey]);

  // Whenever sessionId or sessionKey changes, sync the sessionId to localStorage.
  useEffect(() => {
    localStorage.setItem(sessionKey, sessionId);  // ✅ Store in localStorage
  }, [sessionId, sessionKey]);


  return (
    <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
      <NavBar />
      <ErrorBoundary>
        <div className="container mt-4">

          <UpgradeBar />
          <Fragment>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/learn-more" component={LearnMore} />
              <Route path="/googleads" component={GoogleAds} />
              {/*<Route path= "/contact" component={Contact} />*/}
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/googleads-accounts" component={AccessibleCustomers} />
              <Route path="/create-google-ads-account" component={CreateAccount} />
              <Route path="/campaigns" component={Campaigns} />
              <Route path="/recommendations" component={Recommendations} />
              <Route path="/create-campaign" component={CreateCampaign} />
              <Route path="/write-smart-ad" component={WriteSmartAd} />
              <Route path="/keyword-themes" component={KeywordThemes} />
              <Route path="/geo-location" component={Location} />
              <Route path="/budget" component={Budget} />
              <Route path="/edit-campaign" component={EditCampaign} />
              <Route path="/about" component={About} />
              <Route path="/performance" component={CampaignPerformance} />
              <Route path="/pmax-performance" component={PmaxPerformance} />
              <Route component={NotFound} />
            </Switch>
          </Fragment>
          <AiLogContainer token={token['mytoken']} sessionId={sessionId} />
          <Sidebar />

        </div>
      </ErrorBoundary>
      <Footer />
    </BrowserRouter>
  );
};

export default App;

