import ReactMarkdown from 'react-markdown';

const uniqueArray = ( ar ) => {
    var j = {};
  
    ar.forEach( function(v) {
      j[v+ '::' + typeof v] = v;
    });
  
  
    return Object.keys(j).map(function(v){
      return j[v];
    });
  }   

const microsToCurrency = (micros) => {
    return (micros / 1000000).toFixed(2);
}

const sumByGroup = (data, target, groupby, returnKeys=false, filterby=null, filterValue=null) => {
    /*let costByWeek = {}
    data.forEach(item => {
        if (costByWeek[item[groupby]]) {
            costByWeek[item[groupby]] += item[target];
        } else {
            costByWeek[item[groupby]] = item[target];
        }
    });
    return costByWeek;*/
    let groupedData = {}
    let keys = uniqueArray(data.map(item => item[groupby]))
    if (returnKeys) {
        return keys
    }
    keys.forEach(key => {
        data.forEach(item => {
            if (item[groupby] === key && (filterValue == 'All' || filterValue == '' || item[filterby] == filterValue)) {
                if (groupedData[key]) {
                    groupedData[key] += item[target]
                } else {
                    groupedData[key] = item[target]
                }
            }
        })
    })
    /*
    keys.forEach(item => {
        if (groupedData[item[groupby]]) {
            groupedData[key] += 
        }
    })*/
    return Object.values(groupedData)
};

const groupByKeys = (data, keys) => {
    return Object.values(data.reduce((r, o) => {
        const key = keys.map(k => o[k]).join('|');
        if (!r[key]) {
            r[key] = { 
                ...o, 
                Task: '', 
                Value: 0, 
                week: '', 
                cost: 0, 
                impressions: 0, 
                clicks: 0, 
                interactions: 0, 
                conversions: 0, 
                conversions_value: 0, 
                incremental_conversions: 0, 
                incremental_conversions_value: 0, 
                impressions_shop: 0, 
                impressions_dv: 0, 
                impressions_ser: 0, 
                clicks_shop: 0, 
                clicks_dv: 0, 
                clicks_ser: 0, 
                cost_shop: 0, 
                cost_dv: 0, 
                cost_ser: 0, 
                conversions_shop: 0, 
                conversions_dv: 0, 
                conversions_ser: 0, 
                conversions_value_shop: 0, 
                conversions_value_dv: 0, 
                conversions_value_ser: 0
            };
        }

        r[key].week += (r[key].week ? ', ' : '') + o.week;
        r[key].cost += +o.cost;
        r[key].impressions += +o.impressions;
        r[key].clicks += +o.clicks;
        r[key].interactions += +o.interactions;
        r[key].conversions += +o.conversions;
        r[key].conversions_value += +o.conversions_value;
        r[key].incremental_conversions += +o.incremental_conversions;
        r[key].incremental_conversions_value += +o.incremental_conversions_value;

        // Avoid division by zero
        r[key].cpc = r[key].clicks > 0 ? r[key].cost / r[key].clicks : 0;
        r[key].cpm = r[key].impressions > 0 ? (r[key].cost / r[key].impressions) * 1000 : 0;
        r[key].interaction_rate = r[key].impressions > 0 ? (r[key].interactions / r[key].impressions) * 100 : 0;
        r[key].cost_per_conversion = r[key].conversions > 0 ? r[key].cost / r[key].conversions : 0;
        r[key].conversion_rate = r[key].clicks > 0 ? (r[key].conversions / r[key].clicks) * 100 : 0;
        r[key].incremental_cost_per_conversion = r[key].incremental_conversions > 0 ? r[key].cost / r[key].incremental_conversions : 0;
        r[key].incremental_conversion_rate = r[key].clicks > 0 ? (r[key].incremental_conversions / r[key].clicks) * 100 : 0;
        r[key].incremental_conversions_value_per_cost = r[key].cost > 0 ? r[key].incremental_conversions_value / r[key].cost : 0;

        r[key].impressions_shop += +o.impressions_shop;
        r[key].impressions_dv += +o.impressions_dv;
        r[key].impressions_ser += +o.impressions_ser;

        r[key].clicks_shop += +o.clicks_shop;
        r[key].clicks_dv += +o.clicks_dv;
        r[key].clicks_ser += +o.clicks_ser;

        r[key].cost_shop += +o.cost_shop;
        r[key].cost_dv += +o.cost_dv;
        r[key].cost_ser += +o.cost_ser;

        r[key].conversions_shop += +o.conversions_shop;
        r[key].conversions_dv += +o.conversions_dv;
        r[key].conversions_ser += +o.conversions_ser;

        r[key].conversions_value_shop += +o.conversions_value_shop;
        r[key].conversions_value_dv += +o.conversions_value_dv;
        r[key].conversions_value_ser += +o.conversions_value_ser;

        return r;
    }, {}));
}


const sumValues = (data, metric) => {
    let value = 0;
    data.reduce((r, o) => {
        value += +o[metric];
    });
    return value;
}

const replaceInArray = (arr, search, replace) => {
    for(var i=0; i < arr.length; i++) {
        arr[i] = arr[i].replace(search, replace);
    }
    return arr;
}

const checkForTrue = (data, col) => {
    return checkForValue(data, col, true);
}

const checkForValue = (data, col, value) => {
    let count = 0
    data.map(item => {
        if (item[col] == value) {
            count += 1;
        }
    })
    return count;
}

function MarkdownMessage({ role, content }) {
    // Put the role and the content into one string
    // so the role appears in bold on the same line
    const combined = `**${role}**: ${content}`;
  
    return (
        <ReactMarkdown 
        components={{
            p: ({ node, ...props }) => (
                <p style={{ margin: 0, padding: 0 }}> 
                {/* or padding: "0 0 0.2em" if you still want a bit */}
                {props.children}
              </p>
            ),
            ol: ({ node, ...props }) => (
              <ol style={{ margin: 0, paddingLeft: "1.2em" }}>
                {props.children}
              </ol>
            ),
            ul: ({ node, ...props }) => (
              <ul style={{ margin: 0, paddingLeft: "1.2em" }}>
                {props.children}
              </ul>
            ),
            li: ({ node, ...props }) => (
                <li style={{ margin: 0, padding: 0 }}> 
                {/* or padding: "0 0 0.2em" if you still want a bit */}
                {props.children}
              </li>
            )
          }}
        >
            {combined}
        </ReactMarkdown>
    );
  }

const platformMap = {
    'google_ads': 'Google',
    'meta_ads': 'Meta',
};

const campaignTypeMap = {
    'SEARCH': 'Search',
    'PERFORMANCE_MAX': 'PMax',
    'DEMAND_GEN': 'DGen',
    'SMART': 'Smart',
};

const bidStrategyMap = {
    'MAXIMIZE_CONVERSION_VALUE': 'Max Conv. Value',
    'MAXIMIZE_CONVERSIONS': 'Max Conversions',
    'TARGET_CPA': 'Target CPA',
    'TARGET_ROAS': 'Target ROAS',
    'TARGET_IMPRESSION_SHARE': 'Target Impr. Share',
};

function readableLabel(technicalLabel) {
    const [platformKey, ctypeKey, btypeKey] = technicalLabel.split("-");
  
    // Fallback to the original piece if not found in the maps
    const platform = platformMap[platformKey] || platformKey;
    const campaignType = campaignTypeMap[ctypeKey] || ctypeKey;
    const bidStrategy = bidStrategyMap[btypeKey] || btypeKey;
  
    return `${platform} ${campaignType} – ${bidStrategy}`;
}

function readableLabels(labelArray) {
    return labelArray.map(label => readableLabel(label));
}

export {microsToCurrency, sumByGroup, groupByKeys, sumValues, replaceInArray, checkForTrue, checkForValue, MarkdownMessage, readableLabel, readableLabels, platformMap, campaignTypeMap, bidStrategyMap}
