// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react';


const MessageError = ({ msg }) => {
    return (
        <div className="alert alert-danger alert-dismissible fade show" 
        role="alert" style={{ display: 'inline-block', font:"gotham-rounded-bold", fontSize:'16px' }}>
            {msg}
            
        </div>  
    );
};

export default MessageError