// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, {useState, useEffect, useMemo, useRef, Fragment} from 'react'
import {useCookies} from 'react-cookie'
import {useHistory} from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap';
import Message from './Message'
import MessageWarning from './MessageWarning'
import MessageSuccess from './MessageSuccess'

import {checkForValue} from './Utils';

const api_url = process.env.REACT_APP_API_URL;

// Define the custom event name (must match the one in AiLogContainer)
const TRIGGER_STREAM_EVENT = 'triggerAiStream';

const Recommendations = () => {

    const [token, setToken, removeToken] = useCookies(['mytoken'])
    const [refreshToken, setRefreshToken, removeRefreshToken] = useCookies(['refreshToken'])
    let history = useHistory()

    // store campaign info from the api
    const [recommendations, setRecommendations] = useState([])
    const sessionKey = `token_${token['mytoken']}_session_id`;
    const [sessionId, setSessionId] = useState(() => {
        return localStorage.getItem(sessionKey);  // ✅ Persist session
    });
    const [userPreferences, setUserPreferences] = useState([])

    const [customerId, setCustomerId, removeCustomerID] = useCookies(['customer_id'])
    const [currency, setCurrency, removeCurrency] = useCookies(['currency'])
    const [cidStatus, setCidStatus, removeCidStatus] = useCookies(['cidStatus']) 
    const [campaignId, setCampaignId, removeCampaignID] = useCookies(['campaign_id'])

    // store billing status from the api
    const [billingStatus, setBillingStatus] = useState('APPROVED')


    // to filter the table
    const [status, setStatus] = useState("Action required")

    // to filter the query to be sent to the api
    const [platform, setPlatform] = useState("all")

    // to sort the table
    const [sortBy, setSortBy] = useState("impact"); // Default sorting by impact

    // messages to inform users
    const [message, setMessage] = useState(' Fetching your data... It will take a few seconds.')
    const [messageWarning, setMessageWarning] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')

    // Subscription status
    const [subscriptionStatus, setSubscriptionStatus] = useState('')

    // State for showing details modal
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedRec, setSelectedRec] = useState(null);

    // success message saying campaign was created
    // when user is redirected here after successfully creating a campaign
    // const [successMessage, setSuccessMessage, removeSuccessMessage] = useCookies(['successMessage'])

    

    // if there is no mytoken in the cookie, redirect user to the home page (denying access)
    useEffect(() => {
        if(!token['mytoken'] || token['mytoken'] === 'undefined') {
            // history.push('/')
            window.location.href = '/'
        }
    }, [token])

    // if there is a customer_id in the cookies
    // send it to the backend with the refreshToken
    // where they will be used to get the campaigns info associated with that customer_id and token
    useEffect(() => {
        if(customerId) {

            // tell user you are fetching their data
            setMessage(' Fetching your data... It can take a few seconds.');

            // check subscription status
            const data0 = {
                'mytoken': token['mytoken']
            }

            // Get user auto-apply preferences
            const data1 = {
                'customerId': customerId['customer_id']
            }

            fetch(`${api_url}/api/get-user-preferences/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify(data1),
                
            })
            .then(resp1 => resp1.json())
            .then(resp1 => {
                if (resp1 !== null) {
                    setUserPreferences(resp1);
                }
            })
            .catch(error => console.log(error))

            fetch(`${api_url}/api/get-subscription-status/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify(data0),
                
            })
            .then(resp0 => resp0.json())
            .then(resp0 => {
                if (resp0 !== null) {
                    setSubscriptionStatus(resp0);
                    // Get campaign data if subscriptionStatus != 'trialEnded'
                    if (subscriptionStatus === 'trialEnded') {
                        setMessage('')
                    } else if (subscriptionStatus) {
                        // data to send to the backend
                        const data = { 
                            'mytoken': token['mytoken'], 
                            'refreshToken': refreshToken['refreshToken'], 
                            'customer_id': customerId['customer_id']
                        }

                        fetch(`${api_url}/api/get-recommendations/`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Token ${token['mytoken']}`
                            },
                            body: JSON.stringify(data),
                            
                        })
                        .then(resp => resp.json())
                        .then(resp => {
                            if (resp !== null) {
                                // console.log(resp);
                                setRecommendations(resp);
                                setMessage('')
                            } else if (resp === null) {
                                // console.log(resp);
                                setMessage('');
                                setMessageWarning('You do not have recommendations. Please check back later.')

                            }
                        })
                        .catch(error => console.log(error))
                    }
                }
            })
            .catch(error => console.log(error))

           
            
        }
    }, [customerId, refreshToken, token, subscriptionStatus])

    // if recommendations object has data, delete the 'fetching data' message
    // and get the billing status
    useEffect(() => {
        if(recommendations.length > 0) {
            setMessage('');

            // data to send to the backend
            const data2 = { 
                'mytoken': token['mytoken'], 
                'refreshToken': refreshToken['refreshToken'], 
                'customer_id': customerId['customer_id'], 
                'date_range': "using same model of reporting"
            }

            fetch(`${api_url}/api/get-billing/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify(data2),
                
            })
            .then(resp => resp.json())
            .then(resp => {
                if (resp !== null) {
                    console.log(resp);
                    setBillingStatus(resp);
                } else if (resp === null) {
                    console.log(resp);
                    setBillingStatus(resp);

                }
            })
            .catch(error => {
                console.log(error)
                setMessage('')
                setMessageWarning(toString(error))
            })
        }
    }, [recommendations, customerId, refreshToken, token])


    // filter recommendatgions by status
    const onChangeStatus = (e) => {
        setStatus(e.target.value)

    }

    // filter campaigns by platform
    const onChangePlatform = (e) => {
        setPlatform(e.target.value);

    }

    const onChangeSort = (e) => {
        setSortBy(e.target.value);
    };

    // redirect to step 1 of campaign creation 
    // when user clicks on 'Create campaign' button
    const create = () => {
        history.push('/create-campaign')
    }

    const mmm = () => {
        history.push('/performance')
    }

    // go back button
    const goCampaignsList = () => {
        // if user has refresh token cookie, take them to Accounts page
        if (refreshToken['refreshToken']) {
            history.push('/campaigns')
        // if not, taking them to the home page
        } else {
            history.push('/')
        }
        
    }

    // handle campaign selection
    const handleChange = (item, e) => {
        let isChecked = e.target.checked;
        let campaign_id = item.id;
        
        // Post the value to CustomerID table
        // data to send to the backend
        const data = { 
            'customer_id': customerId['customer_id'],
            'campaign_id': campaign_id,
            'process_automation': isChecked
        }

        fetch(`${api_url}/api/get-campaign-id/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['mytoken']}`
            },
            body: JSON.stringify(data),
        })
        .then(function(response) {    
            console.log(response.text());
        })
        .catch(error => console.log(error))
        
    }

    // handle user preference update
    const handlePreferenceChange = (e, recommendationType) => {
        
        let isChecked = e.target.checked;
        const data = { 
            'customerId': customerId['customer_id'],
            'recommendationType': recommendationType,
            'autoApply': isChecked
        }
        
        fetch(`${api_url}/api/update-user-preference/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['mytoken']}`
            },
            body: JSON.stringify(data),
        })
        .then(function(response) {    
            console.log(response.text());
        })
        .catch(error => console.log(error))

    }

    const approveRecommendation = (recommendationId) => {
        const oldRecs = [...recommendations];
        const itemIndex = oldRecs.findIndex((rec) => rec.id === recommendationId);
        if (itemIndex === -1) return;
      
        const oldStatus = oldRecs[itemIndex].recommendation_status;
        // On click: change local status to "approved" immediately
        oldRecs[itemIndex].recommendation_status = "approved";
        setRecommendations(oldRecs);

        console.log(`Dispatching ${TRIGGER_STREAM_EVENT} event after successful approval.`);
        window.dispatchEvent(new CustomEvent(TRIGGER_STREAM_EVENT));
      
        const data = {
          session_id: sessionId,
          refreshToken: refreshToken["refreshToken"],
          mytoken: token["mytoken"],
          customer_id: customerId["customer_id"],
          recommendation_id: recommendationId,
        };
      
        fetch(`${api_url}/api/approve-recommendation/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token["mytoken"]}`,
          },
          body: JSON.stringify(data),
        })
          .then(async (response) => {
            let respData;
            try {
              respData = await response.json(); 
            } catch (error) {
              // If we can’t parse JSON, revert
              throw new Error("Invalid JSON response");
            }
      
            console.log(respData.message);
      
            if (response.status === 200) {
              // On success code 200: local status => "processed"
              const updatedRecs = [...recommendations];
              updatedRecs[itemIndex].recommendation_status = "processed";
              setRecommendations(updatedRecs);
            } else if (response.status === 500) {
              // On 500: local status => "failed"
              const updatedRecs = [...recommendations];
              updatedRecs[itemIndex].recommendation_status = "failed";
              setRecommendations(updatedRecs);
            } else {
              // On anything else: revert to old status
              const updatedRecs = [...recommendations];
              updatedRecs[itemIndex].recommendation_status = oldStatus;
              setRecommendations(updatedRecs);
            }
      
            if (Array.isArray(respData.messages)) {
              // Optionally incorporate new messages
            } else {
              console.error(
                "Unexpected data format: messages is not an array",
                respData.messages
              );
            }
          })
          .catch((error) => {
            console.log("Error approving recommendation:", error);
            // Also revert on fetch errors
            const updatedRecs = [...recommendations];
            updatedRecs[itemIndex].recommendation_status = oldStatus;
            setRecommendations(updatedRecs);
          });
      };

      const rejectRecommendation = (recommendationId) => {
        const oldRecs = [...recommendations];
        const itemIndex = oldRecs.findIndex((rec) => rec.id === recommendationId);
        if (itemIndex === -1) return;
      
        const oldStatus = oldRecs[itemIndex].recommendation_status;
        // On click: change local status to "rejected"
        oldRecs[itemIndex].recommendation_status = "rejected";
        setRecommendations(oldRecs);
      
        const data = {
          session_id: sessionId,
          refreshToken: refreshToken["refreshToken"],
          mytoken: token["mytoken"],
          customer_id: customerId["customer_id"],
          recommendation_id: recommendationId,
        };
      
        fetch(`${api_url}/api/reject-recommendation/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token["mytoken"]}`,
          },
          body: JSON.stringify(data),
        })
          .then(async (response) => {
            let respData;
            try {
              respData = await response.json();
            } catch (error) {
              throw new Error("Invalid JSON response");
            }
      
            console.log(respData.message);
      
            if (response.status === 200) {
              // On success code 200: local status => still "rejected"
              const updatedRecs = [...recommendations];
              updatedRecs[itemIndex].recommendation_status = "rejected";
              setRecommendations(updatedRecs);
      
              if (Array.isArray(respData.messages)) {
                // Optionally integrate SSE messages
              } else {
                console.error(
                  "Unexpected data format: messages is not an array",
                  respData.messages
                );
              }
            } else {
              // On any other response code, revert to old status
              const updatedRecs = [...recommendations];
              updatedRecs[itemIndex].recommendation_status = oldStatus;
              setRecommendations(updatedRecs);
            }
          })
          .catch((error) => {
            console.log("Error rejecting recommendation:", error);
            // Revert
            const updatedRecs = [...recommendations];
            updatedRecs[itemIndex].recommendation_status = oldStatus;
            setRecommendations(updatedRecs);
          });
    };

    const handleViewDetails = (item) => {
        setSelectedRec(item);
        setShowDetailsModal(true);
    };

    const handleCloseModal = () => {
        setShowDetailsModal(false);
        setSelectedRec(null);
    };


    return (
        
    <div className="container mt-4">
        
        <br/>
        <h4 className="display-4 text-left mb-4 title" >
            Recommendations
        </h4> 

        <br/>
        <button type="button" className="btn btn-link" name="go back" 
        onClick={goCampaignsList} >
            <i className="fas fa-arrow-left fa-2x"></i>
        </button>
        <br/>
        <br/>
        {/* if user does not have billing set up yet */}
        {billingStatus === 'APPROVED' ? null : 
        <Fragment>
            <div className="alert alert-danger alert-dismissible fade show" 
            role="alert" 
            style={{ 
                display: 'inline-block', 
                fontSize:'16px' 
                }}>
                <i className="fas fa-exclamation-triangle fa-fw"
                style={{marginRight: '5px'}}></i>
                Your account cannot show ads. 
                To start running your ads, 
                <a href="https://ads.google.com/home/" className="alert-link"
                target="_blank" rel="noopener noreferrer"> CLICK HERE </a> 
                to enter your billing information. 
                This link will open a new tab with Google Ads platform. 
                Go to Settings in the top menu, 
                and click on Billing & payments. 
                When you finish, 
                close that tab to continue working on Hai Byte.
                
            </div>
        </Fragment>  }

        {/* if user has campaigns, show this message */}
        {recommendations.length > 0 ? 
        <Fragment>
            <p>Select which recommendations to approve or reject. Click <strong>Approve</strong> if you want HAI to implement the recommendation for you, <strong>Reject</strong> if you don't think the recommendation is relevant or good for your business.
            </p>
            <p><i>Note: if you approved a recommendation that is vague, it's good to check manually that the implemented changes are to your liking.</i></p>
            <p> 
            To select which recommendation types can be auto-approved, move to the <strong>Auto-optimization settings</strong> page.
            </p>
        </Fragment> : 
        // if user has zero campaigns yet, show this message
        <Fragment>
            <p>Oh! It seems you do not have ads running on Google yet. 
                Create a campaign to start selling more!
            </p>
        </Fragment>}
        

        <br/>

        {/* <div className="container" align="left"> */}
            
                {/* <div className="col-6"> */}
                    {/*<button onClick={create} className="btn btn-success">Create campaign</button> */}
                    {/* <button onClick={mmm} className="btn btn-success">Performance analysis</button> */}
                {/* </div> */}
            
        {/* </div> */}
        {/* <br/> */}

        {message ? <Message msg={message} /> : null}
        {messageWarning ? <MessageWarning msg={messageWarning} /> : null}
        {messageSuccess ? <MessageSuccess msg={messageSuccess} /> : null}

        {cidStatus['cidStatus'] === 'false' ?
            <Fragment>
                <p><strong>Auto-optimization not enabled</strong> for the account. Please enable auto-optimization at account level to enable it for recommendations. </p>
                <br/>
            </Fragment>
        : checkForValue(recommendations, 'recommendation_status', 'processed') > 0 ?
            <Fragment>
                <p><strong>Auto-optimization enabled</strong> for some recommendation types</p>
                <br/>
            </Fragment>
        : cidStatus['cidStatus'] === 'true' ?
            <Fragment>
                <p><strong>Auto-optimization not enabled</strong> for any recommendation types, even though the account is opted in. Please approve recommendations to start optimizing the account.</p>
                <br/>
            </Fragment>    
        :
            <Fragment>
                <p><strong>Auto-optimization not enabled</strong> for any recommendation types</p>
                <br/>
            </Fragment>    
        }
        <br/>
        <br/>

        <div className="container">
            <div className="row">
                <div className="col-sm" align='left'>
                    <p><i className="fas fa-filter"></i>  Filter by recommendation status</p>
            
                    <div className="btn-group">
                        
                        <select 
                            className="form-select form-select-sm" 
                            onChange={onChangeStatus} 
                            value={status} 
                            aria-label="Filter table by recommendation status"
                        >
                            
                            <option value="All">All</option>
                            <option value="Action required">Action required</option>
                            <option value="All approved">All approved</option>
                            <option value="All rejected">All rejected</option>
                            <option value="All pending">All pending</option>
                        </select>
                    </div>

                </div>

                <div className="col-sm" align='left'>
                    <p><i className="fas fa-filter"></i>  Filter by ad platform</p>
            
                    <div className="btn-group" >
                        
                        <select 
                            className="form-select form-select-sm" 
                            onChange={onChangePlatform} 
                            value={platform} 
                            aria-label="Filter table by ad platform"
                        >
                                
                            <option value="google_ads">Google Ads</option>
                            <option value="meta_ads">Meta Ads</option>
                            <option value="all">All</option>
                        </select>
                    </div>

                </div>

                <div className="col-sm" align="right">
                    <p><i className="fas fa-sort"></i>  Sort by</p>
                    <select 
                        className="form-select form-select-sm" 
                        onChange={onChangeSort} 
                        value={sortBy} 
                        aria-label="Sort recommendations"
                    >
                        <option value="impact">Impact</option>
                        <option value="updated_at">Updated at</option>
                    </select>
                </div>
            </div>
        </div>

        <div className="scroll-wrapper-dark">
        <table className="table table-bordered table-hover table-responsive">
            <thead className="thead-light" >
                <tr key="accounts_table" style={{ textAlign: 'center', verticalAlign: 'top'}}>
                    
                    <th key="recommendation_status" scope="col">Recommendation status</th>
                    <th key="campaign_id" scope="col">Campaign</th>
                    <th key="recommendation" scope="col">Recommendation</th>
                    <th key="estimated_impact" scope="col">Estimated Impact</th>
                    <th key="actual_impact" scope="col">Actual Impact</th>
                    <th key="updated_at" scope="col">Updated at</th>
                    <th key="approve_or_reject" scope="col" colSpan={2}>Approve / reject</th>
                </tr>
            </thead>
           
            <tbody>
                {recommendations.length > 0 && recommendations
                    .filter((item) => 
                    // Platform filter: Show all platforms if "all" is selected, otherwise filter by platform
                    (platform === "all" || platform === item.platform) &&
                    
                    // Campaign ID filter: If a campaign is selected, only show that campaign's recommendations
                    // If no campaign is selected, show all recommendations for the selected customer
                    (campaignId['campaignID']?.trim() && campaignId['campaignID'] !== "All" 
                        ? item.campaign_id === campaignId['campaignID'] 
                        : true)
                    )
                    .sort((a, b) => {
                        if (sortBy === "impact") {
                            const impactA = a.estimated_impact?.value || 0;
                            const impactB = b.estimated_impact?.value || 0;
                            return impactB - impactA; // Highest impact first
                        }
                        if (sortBy === "updated_at") {
                            return new Date(b.updated_at) - new Date(a.updated_at); // Most recent first
                        }
                        return 0;
                    })
                    .map(item => {
                    if (status === "Action required") {
                        return(
                    
                            <tr key={item.id} id={item.id} value={item.id} 
                            style={{ textAlign: 'center', cursor: 'pointer', 
                            display: item.recommendation_status === "pending" || item.recommendation_status === "failed" || item.recommendation_status === "approved" ? '' : 'none'  }}
                            onClick={() => handleViewDetails(item)}
                            >
                                
                                <td>
                                    {item.recommendation_status === "pending" && "🟡 Pending"}
                                    {item.recommendation_status === "approved" && "✅ Approved (pending implementation)"}
                                    {item.recommendation_status === "processed" && "✅ Implemented"}
                                    {item.recommendation_status === "rejected" && "❌ Rejected"}
                                    {item.recommendation_status === "failed" && "❌ Implementation failed"}
                                </td>
                                <td> {item.campaign_name}</td>
                                <td> {item.recommendation}</td>
                                {/* Removed rationale and next_steps */}
                                <td>
                                    {item.estimated_impact && item.estimated_impact.value !== undefined
                                        ? `${item.estimated_impact.metric || "Unknown Metric"}: ${parseFloat(item.estimated_impact.value).toFixed(2)} (${item.estimated_impact.confidence || "N/A"})`
                                        : "N/A"}
                                </td>
                                <td>
                                    {item.actual_impact && item.actual_impact.final ? (
                                        <p>
                                            <strong>Actual Impact:</strong>{" "}
                                            {JSON.stringify(item.actual_impact.impact_analysis)}
                                        </p>
                                    ) : (
                                        <p>(No post-implementation data yet.)</p>
                                    )}
                                </td>
                                <td> 
                                    {new Date(item.updated_at).toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                    })}
                                </td>
                                <td>
                                    {["pending", "rejected", "failed"].includes(item.recommendation_status) ? <button onClick={(e) => {e.stopPropagation(); approveRecommendation(item.id)}} className="btn btn-success">Approve</button> : item.recommendation_status === "approved" ? <i>Pending implementation</i> : <i>Already approved</i>}
                                </td>
                                <td>
                                    {item.recommendation_status === "pending" && <button onClick={(e) => {e.stopPropagation(); rejectRecommendation(item.id)}} className="btn btn-danger">Reject</button>}
                                    {item.recommendation_status === "approved" && <i>Already approved</i>}
                                    {item.recommendation_status === "processed" && <i>Already implemented</i>}
                                    {item.recommendation_status === "rejected" && <i>Already rejected</i>}
                                    {item.recommendation_status === "failed" && <i>Implementation failed</i>}
                                </td>
                                
                            </tr>
                            
                            )

                    } else if (status === "All approved") {
                        return(
                    
                            <tr key={item.id} id={item.id} value={item.id} 
                            style={{ textAlign: 'center', cursor: 'pointer', 
                            display: item.recommendation_status === "approved" || item.recommendation_status === "processed" ? '' : 'none'  }}
                            onClick={() => handleViewDetails(item)}
                            >
                                
                                <td>
                                {item.recommendation_status === "pending" && "🟡 Pending"}
                                    {item.recommendation_status === "approved" && "✅ Approved (pending implementation)"}
                                    {item.recommendation_status === "processed" && "✅ Implemented"}
                                    {item.recommendation_status === "rejected" && "❌ Rejected"}
                                    {item.recommendation_status === "failed" && "❌ Implementation failed"}
                                </td>
                                <td> {item.campaign_name}</td>
                                <td> {item.recommendation}</td>
                                {/* Removed rationale and next_steps */}
                                <td>
                                    {item.estimated_impact && item.estimated_impact.value !== undefined
                                        ? `${item.estimated_impact.metric || "Unknown Metric"}: ${parseFloat(item.estimated_impact.value).toFixed(2)} (${item.estimated_impact.confidence || "N/A"})`
                                        : "N/A"}
                                </td>
                                <td>
                                    {item.actual_impact && item.actual_impact.final ? (
                                        <p>
                                            <strong>Actual Impact:</strong>{" "}
                                            {JSON.stringify(item.actual_impact.impact_analysis)}
                                        </p>
                                    ) : (
                                        <p>(No post-implementation data yet.)</p>
                                    )}
                                </td>
                                <td> 
                                    {new Date(item.updated_at).toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                    })}
                                </td>
                                <td>
                                    {["pending", "rejected", "failed"].includes(item.recommendation_status) ? <button onClick={(e) => {e.stopPropagation(); approveRecommendation(item.id)}} className="btn btn-success">Approve</button> : item.recommendation_status === "approved" ? <i>Pending implementation</i> : <i>Already approved</i>}
                                </td>
                                <td>
                                    {item.recommendation_status === "pending" && <button onClick={(e) => {e.stopPropagation(); rejectRecommendation(item.id)}} className="btn btn-danger">Reject</button>}
                                    {item.recommendation_status === "approved" && <i>Already approved</i>}
                                    {item.recommendation_status === "processed" && <i>Already implemented</i>}
                                    {item.recommendation_status === "rejected" && <i>Already rejected</i>}
                                    {item.recommendation_status === "failed" && <i>Implementation failed</i>}
                                </td>

                            </tr>

                            )
                    } else if (status === "All rejected") {
                    return(
                
                        <tr key={item.id} id={item.id} value={item.id} 
                        style={{ textAlign: 'center', cursor: 'pointer', 
                        display: item.recommendation_status === "rejected" ? '' : 'none'  }}
                        onClick={() => handleViewDetails(item)}
                        >
                            
                            <td>
                            {item.recommendation_status === "pending" && "🟡 Pending"}
                                    {item.recommendation_status === "approved" && "✅ Approved (pending implementation)"}
                                    {item.recommendation_status === "processed" && "✅ Implemented"}
                                    {item.recommendation_status === "rejected" && "❌ Rejected"}
                                    {item.recommendation_status === "failed" && "❌ Implementation failed"}
                            </td>
                            <td> {item.campaign_name}</td>
                            <td> {item.recommendation}</td>
                            {/* Removed rationale and next_steps */}
                            <td>
                                {item.estimated_impact && item.estimated_impact.value !== undefined
                                    ? `${item.estimated_impact.metric || "Unknown Metric"}: ${parseFloat(item.estimated_impact.value).toFixed(2)} (${item.estimated_impact.confidence || "N/A"})`
                                    : "N/A"}
                            </td>
                            <td>
                                {item.actual_impact && item.actual_impact.final ? (
                                    <p>
                                        <strong>Actual Impact:</strong>{" "}
                                        {JSON.stringify(item.actual_impact.impact_analysis)}
                                    </p>
                                ) : (
                                    <p>(No post-implementation data yet.)</p>
                                )}
                            </td>
                            <td> 
                                {new Date(item.updated_at).toLocaleString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                })}
                            </td>
                            <td>
                                {["pending", "rejected", "failed"].includes(item.recommendation_status) ? <button onClick={(e) => {e.stopPropagation(); approveRecommendation(item.id)}} className="btn btn-success">Approve</button> : item.recommendation_status === "approved" ? <i>Pending implementation</i> : <i>Already approved</i>}
                            </td>
                            <td>
                                {item.recommendation_status === "pending" && <button onClick={(e) => {e.stopPropagation(); rejectRecommendation(item.id)}} className="btn btn-danger">Reject</button>}
                                {item.recommendation_status === "approved" && <i>Already approved</i>}
                                {item.recommendation_status === "processed" && <i>Already implemented</i>}
                                {item.recommendation_status === "rejected" && <i>Already rejected</i>}
                                {item.recommendation_status === "failed" && <i>Implementation failed</i>}
                            </td>

                        </tr>

                        )
                    } else if (status === "All pending") {
                        return(
                    
                            <tr key={item.id} id={item.id} value={item.id} 
                            style={{ textAlign: 'center', cursor: 'pointer', 
                            display: item.recommendation_status === "pending" ? '' : 'none'  }}
                            onClick={() => handleViewDetails(item)}
                            >
                                
                                <td>
                                {item.recommendation_status === "pending" && "🟡 Pending"}
                                    {item.recommendation_status === "approved" && "✅ Approved (pending implementation)"}
                                    {item.recommendation_status === "processed" && "✅ Implemented"}
                                    {item.recommendation_status === "rejected" && "❌ Rejected"}
                                    {item.recommendation_status === "failed" && "❌ Implementation failed"}
                                </td>
                                <td> {item.campaign_name}</td>
                                <td> {item.recommendation}</td>
                                {/* Removed rationale and next_steps */}
                                <td>
                                    {item.estimated_impact && item.estimated_impact.value !== undefined
                                        ? `${item.estimated_impact.metric || "Unknown Metric"}: ${parseFloat(item.estimated_impact.value).toFixed(2)} (${item.estimated_impact.confidence || "N/A"})`
                                        : "N/A"}
                                </td>
                                <td>
                                    {item.actual_impact && item.actual_impact.final ? (
                                        <p>
                                            <strong>Actual Impact:</strong>{" "}
                                            {JSON.stringify(item.actual_impact.impact_analysis)}
                                        </p>
                                    ) : (
                                        <p>(No post-implementation data yet.)</p>
                                    )}
                                </td>
                                <td> 
                                    {new Date(item.updated_at).toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                    })}
                                </td>
                                <td>
                                    {["pending", "rejected", "failed"].includes(item.recommendation_status) ? <button onClick={(e) => {e.stopPropagation(); approveRecommendation(item.id)}} className="btn btn-success">Approve</button> : item.recommendation_status === "approved" ? <i>Pending implementation</i> : <i>Already approved</i>}
                                </td>
                                <td>
                                    {item.recommendation_status === "pending" && <button onClick={(e) => {e.stopPropagation(); rejectRecommendation(item.id)}} className="btn btn-danger">Reject</button>}
                                    {item.recommendation_status === "approved" && <i>Already approved</i>}
                                    {item.recommendation_status === "processed" && <i>Already implemented</i>}
                                    {item.recommendation_status === "rejected" && <i>Already rejected</i>}
                                    {item.recommendation_status === "failed" && <i>Implementation failed</i>}
                                </td>

                            </tr>  
                            
                            )
                    } else {
                        return(
                    
                            <tr key={item.id} id={item.id} value={item.id} 
                            style={{ textAlign: 'center', cursor: 'pointer'}}
                            onClick={() => handleViewDetails(item)}
                            >
                                
                                <td>
                                {item.recommendation_status === "pending" && "🟡 Pending"}
                                    {item.recommendation_status === "approved" && "✅ Approved (pending implementation)"}
                                    {item.recommendation_status === "processed" && "✅ Implemented"}
                                    {item.recommendation_status === "rejected" && "❌ Rejected"}
                                    {item.recommendation_status === "failed" && "❌ Implementation failed"}
                                </td>
                                <td> {item.campaign_name}</td>
                                <td> {item.recommendation}</td>
                                {/* Removed rationale and next_steps */}
                                <td>
                                    {item.estimated_impact && item.estimated_impact.value !== undefined
                                        ? `${item.estimated_impact.metric || "Unknown Metric"}: ${parseFloat(item.estimated_impact.value).toFixed(2)} (${item.estimated_impact.confidence || "N/A"})`
                                        : "N/A"}
                                </td>
                                <td>
                                    {item.actual_impact && item.actual_impact.final ? (
                                        <p>
                                            <strong>Actual Impact:</strong>{" "}
                                            {JSON.stringify(item.actual_impact.impact_analysis)}
                                        </p>
                                    ) : (
                                        <p>(No post-implementation data yet.)</p>
                                    )}
                                </td>
                                <td> 
                                    {new Date(item.updated_at).toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                    })}
                                </td>
                                <td>
                                    {["pending", "rejected", "failed"].includes(item.recommendation_status) ? <button onClick={(e) => {e.stopPropagation(); approveRecommendation(item.id)}} className="btn btn-success">Approve</button> : item.recommendation_status === "approved" ? <i>Pending implementation</i> : <i>Already approved</i>}
                                </td>
                                <td>
                                    {item.recommendation_status === "pending" && <button onClick={(e) => {e.stopPropagation(); rejectRecommendation(item.id)}} className="btn btn-danger">Reject</button>}
                                    {item.recommendation_status === "approved" && <i>Already approved</i>}
                                    {item.recommendation_status === "processed" && <i>Already implemented</i>}
                                    {item.recommendation_status === "rejected" && <i>Already rejected</i>}
                                    {item.recommendation_status === "failed" && <i>Implementation failed</i>}
                                </td>
                                
                            </tr>
                            
                            )
                    }
                
                })}

            </tbody>
        </table>
        </div>
        {/* 4) Details Modal that shows rationale & next_steps */}
        {selectedRec && (
            <Modal show={showDetailsModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Recommendation Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Recommendation</h6>
                <p>{selectedRec.recommendation}</p>
                <h6>Rationale</h6>
                <p>{selectedRec.rationale}</p>
                <h6>Next Steps</h6>
                <p>{selectedRec.next_steps}</p>
                <h6>Estimated Impact</h6>
                <p>{selectedRec.estimated_impact && selectedRec.estimated_impact.value !== undefined
                                    ? `${selectedRec.estimated_impact.metric || "Unknown Metric"}: ${parseFloat(selectedRec.estimated_impact.value).toFixed(2)} (${selectedRec.estimated_impact.confidence || "N/A"})`
                                    : "N/A"}
                </p>
                <h6>Actual Impact</h6>
                <p>{selectedRec.actual_impact && selectedRec.actual_impact.final ? (
                                    <p>
                                        <strong>Actual Impact:</strong>{" "}
                                        {JSON.stringify(selectedRec.actual_impact.impact_analysis)}
                                    </p>
                                ) : (
                                    <p>(No post-implementation data yet.)</p>
                                )}
                </p>
                <h6>Updated At</h6>
                <p>{selectedRec.updated_at}</p>
            </Modal.Body>
            <Modal.Footer>
                {["pending", "rejected", "failed"].includes(selectedRec.recommendation_status) ? <button onClick={() => approveRecommendation(selectedRec.id)} className="btn btn-success">Approve</button> : selectedRec.recommendation_status === "approved" ? <i>Pending implementation</i> : <i>Already approved</i>}
                {selectedRec.recommendation_status === "pending" && <button onClick={() => rejectRecommendation(selectedRec.id)} className="btn btn-danger">Reject</button>}
                {selectedRec.recommendation_status === "approved" && <i>Already approved</i>}
                {selectedRec.recommendation_status === "processed" && <i>Already implemented</i>}
                {selectedRec.recommendation_status === "rejected" && <i>Already rejected</i>}
                {selectedRec.recommendation_status === "failed" && <i>Implementation failed</i>}
                <Button variant="secondary" onClick={handleCloseModal}>
                Close
                </Button>
            </Modal.Footer>
            </Modal>
        )}
        <br/>
    </div>
)}

export default Recommendations;